<!-- A NE PAS SUPPRIMER -->

<h1>Evaluations</h1>
<ul class="nav">
  <li class="nav-item">
    <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
  </li>
  <li class="nav-item">
    <a class="nav-link disabled">Evaluations</a>
  </li>
</ul>
<div class="recherche">
  <h4>Evaluations</h4>
  <div class="row">
    <div class="col-md-8">
      <form #search="ngForm" (ngSubmit)="onClickSubmit(search.value)">
        <div class="form-row ">


          <div class="form-group col-md-4">
            <label for="periode">Trimestre</label>
            <select name="periode" id="periode" class="form-control" ngModel>
              <option *ngFor="let p of periodes" [value]="p"> {{p}} </option>
            </select>
          </div>

          <div class="form-group col-md-4">
            <label for="type">Type d'évaluation</label>
            <select name="type" id="type" class="form-control" ngModel>
              <option *ngFor="let t of types" [value]="t.codeval"> {{t.type_evaluation}} </option>
            </select>
          </div>

          <div class="form-group col-md-4">
            <label for="classe">Classes</label>
            <select name="classe" class="form-control" ngModel>
              <option *ngFor="let c of classes" [value]="c.idclasse">{{c.classe_nom}} </option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="matiere">Matière</label>
            <select name="matiere" id="matiere" class="form-control" ngModel>
              <option *ngFor="let m of matieres" [value]="m.idmatiere">
                {{m.matiere_nom}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="debut">Du</label>
            <input type="date" name="debut" id="debut" class="form-control" ngModel>
          </div>
          <div class="form-group col-md-4">
            <label for="fin">Au</label>
            <input type="date" name="fin" id="fin" class="form-control" ngModel>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="limite">Nombre à afficher</label>
            <select name="limite" id="limite" class="form-control" ngModel>
              <option [value]="'20'">20</option>
              <option [value]="'50'">50</option>
              <option [value]="'100'">100</option>
              <option [value]="'250'">250</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="ordre">Ordre</label>
            <select name="ordre" id="ordre" class="form-control" ngModel>
              <option [value]="'DESC'">DESC</option>
              <option [value]="'ASC'">ASC</option>

            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="id">Numéro d'évaluation</label>
            <input type="number" name="id" class="form-control" id="id" ngModel>
          </div>

        </div>
        <div class="form-group">
          <button type="submit" [disabled]="submitted || search.value == null">
            <span *ngIf="!submitted">Rechercher</span>
            <span *ngIf="submitted">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Recherche...
            </span>
          </button>
        </div>

      </form>
    </div>
    <div class="col-md-4">
      <p>Créer une nouvelle évaluation</p>
      <p>
        <button class="button btn-sm" (click)="open(content)">Nouvelle évaluation</button>
      </p>
      <p> <a (click)="getEvaluations()" class="a">Afficher toutes les évaluations</a> </p>
    </div>
  </div>
</div>
<div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}} </div>
<div class="table-responsive">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">N°</th>
        <th scope="col">Date</th>
        <th scope="col">Evaluation</th>
        <th scope="col">Classe</th>
        <th scope="col">Matière</th>
        <th scope="col">Période</th>
        <th scope="col">Noté sur</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let e of evaluation | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize"
        [ngClass]="{ 'alert-warning': e.date === (today | date: 'Y-MM-dd') && e.executee == 0,'barre': e.date < (today | date: 'Y-MM-dd') && e.executee == 0 , 'alert-success': e.executee == 1}">
        <td> {{e.id}} </td>
        <td> {{e.date | date}} {{e.heure}} </td>
        <td> {{e.type_evaluation}} </td>
        <td> {{e.classe_nom}} </td>
        <td> {{e.idmatiere}} </td>
        <td> {{e.periode}} </td>
        <td> {{e.notation}} </td>
        <td>
          <a routerLink="view/{{e.idevaluation}}">Voir detail</a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ngb-pagination [collectionSize]="collection" [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true" maxSize=5
  *ngIf="collection > 10" class="float-right">
  <ng-template ngbPaginationFirst>Premier</ng-template>
  <ng-template ngbPaginationLast>Dernier</ng-template>
  <ng-template ngbPaginationPrevious>Précédent</ng-template>
  <ng-template ngbPaginationNext>Suivant</ng-template>
  <ng-template ngbPaginationEllipsis>...</ng-template>
  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
</ngb-pagination>

<div *ngIf="loading" class="text-center">
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-header">
      <h6 class="modal-title" id="modal-basic-title">
        Nouvelle évaluation
      </h6>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="type">Type d'évaluation</label>
          <select formControlName="type" id="type" class="form-control">
            <option *ngFor="let t of types" [value]="t.codeval"> {{t.type_evaluation}} </option>
          </select>
        </div>

        <div class="form-group col-md-6">
          <label for="periode">Période</label>
          <select formControlName="periode" id="periode" class="form-control">
            <option *ngFor="let p of periodes" [value]="p"> {{p}} </option>
          </select>
        </div>
      </div>


      <div class="form-row">
        <div class="form-group col-md-8" *ngIf="user.role === 'professeur' ">
          <label for="classe">Classe</label>
          <select class="form-control" formControlName="classe" id="classe">
            <option *ngFor="let c of classes" [value]="c.idclasse">{{c.classe_nom}}</option>
          </select>
        </div>
        <div class="form-group col-md-8" *ngIf="user.role === 'directeur' ">
          <label for="classe">Classe</label>
          <select class="form-control" formControlName="classe" id="classe">
            <option *ngFor="let branche of branches" [value]="branche.code">{{branche.nom}}</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label for="note">Noter sur :</label>
          <input type="number" formControlName="note" id="note" class="form-control" min="5">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-7">
          <label for="matiere">Matière</label>
          <select class="form-control" formControlName="matiere" id="matiere">
            <option *ngFor="let m of matieres" [value]="m.idmatiere">{{m.matiere_nom}}</option>
          </select>
        </div>
        <div class="form-group col-md-5" *ngIf="getSousMatieres(form.get('matiere').value)">
          <label for="sous_matiere">Sous matière</label>
          <select formControlName="sous_matiere" class="form-control" id="sous_matiere">
            <option *ngFor="let mat of getSousMatieres(form.get('matiere').value)" [value]="mat">{{mat}}</option>
          </select>
        </div>
        <div class="form-group col-md-5"
          *ngIf="form.get('periode').value === 'Composition' || form.get('periode').value === 'Composition finale' ">
          <label for="mois">Mois</label>
          <select formControlName="mois" class="form-control" id="mois">
            <option *ngFor="let ms of mois" [value]="ms">{{ms}}</option>
          </select>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="jour">Date</label>
          <input type="date" formControlName="jour" id="jour" class="form-control">
        </div>
        <div class="form-group col-md-4">
          <label for="heure">Heure</label>
          <input type="time" formControlName="heure" id="heure" class="form-control">
        </div>
        <div class="form-group col-md-4">
          <label for="duree">Durée</label>
          <input type="time" formControlName="duree" id="duree" class="form-control">
        </div>
      </div>
      <div class="form-group">
        <div class="form-check">
          <input class="form-check-input" formControlName="executee" type="checkbox" [value]="1" id="executee">
          <label class="form-check-label" for="executee">
            Considérer l'évaluation comme éxécutée
          </label>
        </div>
      </div>
      <div class="form-group">
        <button type="submit" [disabled]="form.invalid || submitted === true">
          <span *ngIf="!submitted">Enrégistrer</span>
          <span *ngIf="submitted">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>enrégistrement
          </span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
