import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfesseurComponent } from './professeur.component';
import { ProfesseurHomeComponent } from './professeur-home/professeur-home.component';
import { LoginGuard } from '../guards/login.guard';

const routes: Routes = [
  {
    path: '', component: ProfesseurComponent,
    canActivate: [LoginGuard],
    children: [
      { path: 'home', component: ProfesseurHomeComponent },
      { path: 'seances', loadChildren: () => import('../suivis/seances/seances.module').then(m => m.SeancesModule) },
      // { path: 'seances', loadChildren: () => import('../pedagogie/seances/seances.module').then(m => m.SeancesModule)},
      // { path: 'absences', loadChildren: () => import('../suivis/absences/absences.module').then(m => m.AbsencesModule) },
      // {
      //   path: 'cahiers-textes',
      //   loadChildren: () => import('../suivis/cahiers-textes/cahiers-textes.module').then(m => m.CahiersTextesModule)
      // },
      {
        path: 'evaluations',
        loadChildren: () => import('../suivis/evaluations/evaluations.module').then(m => m.EvaluationsModule)
      },
      {
        path: 'notes',
        loadChildren: () => import('../suivis/notes/notes.module').then(m => m.NotesModule)
      },
      { path: 'pedagogie', loadChildren: () => import('../pedagogie/pedagogie.module').then(m => m.PedagogieModule) }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfesseurRoutingModule { }
